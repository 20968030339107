import { Container, Grid, Typography } from "@mui/material";
import { motion, useAnimation, useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactPlayer from "react-player";
import Head from "../../components/JP/Head";
import Header from "../../components/JP/Header";
import Footer from "../../components/JP/footer";
import "./About.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SIR from "../../assets/SIR.png";
import ITHO from "../../assets/ITHO.jpg";
import VCIMG from "../../assets/VC.jpeg";
import ADV from "../../assets/ADV.jpg";
import { Box } from "@mui/system";

const About = () => {
  const [videos, setVideos] = useState([]);

  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://kiu.ac.lk/api/website/JP/findLast4Students")
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response is an array of objects with "testamonial" and
        // "thumbnail" properties
        setVideos(data || []);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="about">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <div ref={ref}>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: 75,
            },
            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            duration: 0.5,
            delay: 0.25,
          }}
        >
          <Container className="about-topic">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontSize: "35px",
                    marginTop: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  現代的な視点、時を超えた私たちの旅を明らかにする
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </motion.div>

        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: 75,
            },
            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            duration: 0.5,
            delay: 0.25,
          }}
        >
          <Container className="about-history">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <h1 className="about-past-h1">私たちの始まり・・・</h1>

                <p className="about-past-p">
                  KIU は、2009 年に設立された、高等教育省の認可を受け、1978
                  年大学法第 16 号第 25 A
                  条に基づきスリランカ大学助成委員会に承認された、一流の非公立の学位授与高等教育機関です。2015
                  年に KIU は学位授与機関としての地位を獲得し、当初 3
                  つの学位プログラムを開始しましたが、現在は 14
                  の学部プログラムと 5 つの大学院プログラムに拡大しています。8
                  つの学部と 17 の学科があります。さらに、KIU
                  は先駆的な学位と世界クラスの施設を備えたスリランカで高く評価されている大学の
                  1
                  つです。当校のコースは、学生が選択した研究分野で夢のキャリアをスタートするためのプラットフォームを提供します。当校は、心を刺激し、知識の追求を奨励する環境を整えています。
                </p>
              </Grid>

              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <h1 className="about-present-h1">私たちの進化</h1>

                <p className="about-present-p">
                  KIU
                  は、グローバルな分野を通じて世界クラスのプロフェッショナルを育成するための登竜門となることを目指しています。当大学は、優れた学術専門家チームの指導を通じて、学生に卓越した学術体験を提供しています。この取り組みに沿って、当大学は
                  KIU キャンパス (メイン キャンパス)、サクラ
                  キャンパス、京伊藤キャンパスの 3
                  つのキャンパスを擁しています。現在、当大学は、何千人もの地元学生と留学生に多様な学習機会を提供しています。当大学の目標は、スリランカ屈指の私立大学という名誉ある地位を獲得し、学生と学者の両方にとって最高の選択肢となることです。当大学は、学生の最高水準を維持し、世界最高に匹敵する比類のない学生体験を保証することに尽力しています。
                </p>
              </Grid>
            </Grid>
          </Container>
        </motion.div>

        <div className="about-horizontal-divider"></div>

        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: 75,
            },
            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            duration: 0.5,
            delay: 0.25,
          }}
        >
          <Container className="about-topic">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontSize: "35px",
                    marginTop: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  明日の可能性を切り拓く：私たちのビジョンとミッション
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </motion.div>

        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: 75,
            },
            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            duration: 0.5,
            delay: 0.25,
          }}
        >
          <Container className="about-future">
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <h1 className="about-vision-h1">私たちのビジョン</h1>

                <p className="about-vision-p">
                  私たちの目標は、学生が、知的、社会的、そして個人的に変革をもたらし、世界のニーズにふさわしい最高品質の力を与える、比類のない教育の旅を体験できるようにすることです。
                </p>
              </Grid>

              <Grid
                item="item"
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <h1 className="about-mission-h1">我々の使命</h1>

                <p className="about-mission-p">
                  国際的に最高レベルの教育、学習研究、革新の追求を通じて社会的平等に貢献します。
                </p>
              </Grid>
            </Grid>
          </Container>
        </motion.div>

        <div className="about-horizontal-divider"></div>

        <Container className="about-topic">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                学長からの メッセージ
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container className="about-vc">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <LazyLoadImage
                src={ITHO}
                className="about-vc-img"
                style={{
                  width: "100%",
                }}
              />

              <p className="about-vc-p">KIU 学長、伊藤恭博士</p>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <p className="about-vcm-p">
                KIU大学へようこそ。当大学は、学生と教職員が学問と個人の卓越性の最高基準を
                達成するために尽力しています。伝統と革新の交差点に立ち、世界基準に準拠し
                つつも、スリランカの独自の文化遺産を受け入れる教育を提供しています。私たち
                の使命は、知識、スキル、価値観を備えた世界クラスのリーダーを育成し、ますます
                相互に結びついた世界で卓越するための準備をすることです。
              </p>

              <p className="about-vcm-p">
                私たちは国際的なリーチに誇りを持ち、世界中の主要大学や機関と協力していま
                す。これらのパートナーシップは、学生と教職員に無限の機会を提供し、アイデア、
                文化的視点、最先端の研究の豊かな交流を促進します。日本の学術リーダーとして
                、私は日本の規律、回復力、卓越性の絶え間ない追求というビジョンに触発されて
                います。私たちはこれらの価値を学生に植え付け、良心的なグローバル市民として
                成長するよう準備させることを目指しています。
              </p>

              <p className="about-vcm-p">
                KIU大学は単なる教育機関ではなく、成長、革新、リーダーシップを促進するコミュ
                ニティです。私たちは、すべての学生が成長し、夢が育まれ、将来の成功の種がま
                かれる環境を作り出すことに努めています。共に、学問的卓越性と国際的な関与の
                旅に乗り出し、KIU大学のビジョンを世界に広めましょう。
              </p>
            </Grid>
          </Grid>
        </Container>

        <div className="about-horizontal-divider"></div>

        <Container className="about-topic">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                松本寿一 名誉顧問
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container className="about-vc">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <LazyLoadImage
                src={ADV}
                className="about-vc-img"
                style={{
                  width: "100%",
                }}
              />

              <p className="about-vc-p">Matsumoto Hisaichi</p>

              <p className="about-vc-p">エキスパートアドバイザー</p>

              <p className="about-vc-p"></p>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <strong>
                <p className="about-vcm-p">
                  本日、高松市、香川県、そして日本全国に素晴らしい足跡を残した著名な人物、松本久一氏の素晴らしい業績を祝うために集まったことを大変光栄に思います。
                </p>
              </strong>

              <p className="about-vcm-p">
                松本久一氏は、若い頃から芸術において類まれな才能を発揮していました。氏は、一度しか作らない素晴らしい作品を生み出し、それぞれの作品が本当に唯一無二の作品となっています。氏の芸術的才能は、高松市ですぐに評判となり、同市では氏の作品が称賛され、賞賛されています。
              </p>

              <p className="about-vcm-p">
                松本久一氏は、芸術的才能だけでなく、熱心なゴルフ愛好家でもあります。同氏はゴルフを愛好しており、若い頃から高松市のゴルフコミュニティではよく知られた人物となっています。{" "}
              </p>
              <p className="about-vcm-p">
                松本久一議員の持続可能な未来へのビジョンは、若い頃に日本にリサイクルの概念を情熱的に紹介したときに始まりました。さまざまな世論に直面したにもかかわらず、彼はリサイクルが日本を新たな高みに引き上げるという信念を固く持ち続けました。今日、彼は北海道から沖縄まで広がるリサイクル会社の広大なネットワークを所有しており、環境の持続可能性に対する彼の永続的な取り組みの証です。
              </p>
              <p className="about-vcm-p">
                彼の貧しい始まりから億万長者になるまでの道のりは、忍耐と献身の物語です。松本久一議員は、彼の指導の下で将来のリーダーを育て、援助の手を差し伸べることを忘れません。彼の寛大さは、Covid-19の発生中に多額の寄付を行い、県議会に多大な支援を提供することに表れています。{" "}
              </p>
              <p className="about-vcm-p">
                松本久一氏は数々の功績を残したほか、安倍晋三前首相の側近として活躍。ユニクロブランドで世界的に知られるビジネスリーダー、柳井正氏とも強固な人脈を築いています。
              </p>
              <p className="about-vcm-p">
                松本久一氏の貢献は政府高官から高く評価されています。バンドゥラ・グナワルダネ大臣とそのチームの日本訪問の促進に重要な役割を果たし、スリランカに川崎重工を紹介しました。氏の信頼性と同氏の会社が生み出した多額の収益は、日本政府から高い評価を得ています。
              </p>
              <p className="about-vcm-p">
                本日、KIUは、同氏の素晴らしい功績と世界への貢献を認め、KIUの専門顧問の称号を同氏に授与することを誇りに思います。{" "}
              </p>
            </Grid>
          </Grid>
        </Container>

        <div className="about-horizontal-divider"></div>

        <Container className="about-topic">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                理事長からの メッセージ
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container className="about-vc">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <LazyLoadImage
                src={SIR}
                className="about-vc-img"
                style={{
                  width: "100%",
                }}
              />

              <p className="about-vc-p">KIU 会長-Dr.ジャガス・セネビラトネ</p>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <p className="about-vcm-p">あなたの未来はグローバルです</p>

              <p className="about-vcm-p">
                今日成功するには、グローバルな視点と、多様な伝統を持つ異文化の人々と協力する
                スキルが必要です。 KIU
                で皆さんは豊かな文化に触れることにより、地域と世界の両方のニーズを満たす有
                能で責任ある専門家として、この大学からはばたくことでしょう。
              </p>

              <p className="about-vcm-p">
                私たちの高い基準と革新的な学習アプローチにより、学生たちは複雑な問題に対処し、
                解決する能力が向上します。私たちの教育現場である研究室、スキルトレーニングラ
                ボ、IT施設は、国のニーズを満たすために常にアップグレードされています。私たちは、
                増加する学生に対応するために機関を拡張することで、より多くの卒業生を求める国
                の需要に応えてきました。
              </p>

              <p className="about-vcm-p">
                大学教育は人生の出発点です。
                もちろん、それは専門的な資格と高く評価される学位を取得することです。しかし、そ
                れ以上に得るものがたくさんあります。 KIU
                から得られる深い知識は、人として成長して新たな視点を発見し、新しい学習方法
                に触れることに役立つことでしょう。
              </p>

              <p className="about-vcm-p">
                大学の選択は、あなたがこれまでに行う最大の決断の1つです。
                あなたの選択が何であれ、私たちはあなたの学習が成功することを願っています。
              </p>
            </Grid>
          </Grid>
        </Container>

        <div className="about-horizontal-divider"></div>
        <Container className="about-topic">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                副学長からのメッセージ
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container className="about-vc">
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <LazyLoadImage
                src={VCIMG}
                className="about-vc-img"
                style={{
                  width: "100%",
                }}
              />

              <p className="about-vc-p">副学長</p>

              <p className="about-vc-p">サジーワニー・リヤナ・アラチチ博士</p>

              <p className="about-vc-p"></p>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <p className="about-vcm-p">
                KIU 大学へようこそ。KIU
                大学は、学術の卓越性と革新の促進に専心する名門大学です。高等教育省の認可を受け、大学助成委員会に認められ、連邦大学に誇りを持って名を連ねる大学として、私たちは学生に世界クラスの教育を提供することに尽力しています。
              </p>

              <p className="about-vcm-p">
                KIU
                では、学問と産業界の間の溝を埋め、学生が世界の労働力の要求に十分対応できるよう努めています。カリキュラムは、学生が選択した分野で成功するために必要なスキル、知識、態度を身に付けられるよう、綿密に設計されています。
              </p>

              <p className="about-vcm-p">
                KIU の卒業生は、KIU
                で堅実かつ業界に関連した教育を受けており、世界中の雇用主から非常に求められています。KIU
                大学は、最先端の研究室、高度な研究センター、近代的な教室、そして学生の学問的および個人的な成長をサポートする広範な図書館リソースを完備しています。研究と革新は私たちの使命の中心であり、私たちは研究活動を継続的に拡大しています。これにより、新しいアイデアや発見が花開く環境が生まれます。私たちの学術プログラムは、専門団体や業界からその質と関連性が認められています。
              </p>

              <p className="about-vcm-p">
                私たちは、学生がスポーツ、芸術、リーダーシップ、ボランティア活動など、さまざまな課外活動に参加できる、活気に満ちた包括的なキャンパスコミュニティを創り出すことに誇りを持っています。この総合的なアプローチにより、学生は学業で優れた成績を収めると同時に、社会的責任と文化的意識を身につけることができます。
              </p>
              <p className="about-vcm-p">
                KIU
                は将来を見据え、質の高い教育を提供し、学術的卓越性の最前線に立つというビジョンを掲げ続けています。KIU
                大学で得られる機会を発見し、知識、革新、そして世界への影響の探求にご参加ください。
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="about-horizontal-divider"></div>

      <Container className="about-topic">
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              学生の声: キャンパスの話題
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="about-st">
        <Grid container="container" spacing={2}>
          {videos.map((item, index) => (
            <Grid
              key={index}
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 1,
                  px: 1,
                }}
              >
                <ReactPlayer
                  url={item.testamonial}
                  controls={true}
                  playing={index === 0}
                  // Autoplay only for the first video
                  width="100%"
                  height="35vh"
                  light={item.thumbnail}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload", // Disable download button
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default About;
